import * as Yup from 'yup';
import { useBusinessStore, BusinessCreationStatus } from '../store/BusinessStore';
import ErrorAlert from '../errors/ErrorAlert';
import { eventGa4Service } from '../ga4.service.tsx';
import { CONTAPRO_ANALYTICS } from '../shared/analytics.tsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import registerSuccessImg from '/assets/spc_mobile_success_hand_d_l.svg?url';
import exclamationIcon from '/assets/ic_exclamation_triangle.svg?url';
import FormComponent from './FormComponent.tsx';

import { RegisterBusinessForm } from './helper.ts';
import { Link, useNavigate } from 'react-router-dom';
import { Button, buttonVariants } from '@/components/ui/button.tsx';
import { ChevronLeftIcon } from 'lucide-react';
import CredicorpFooter from '@/shared/CredicorpFooter.tsx';
import isValidRUC from '@/helpers/isValidRUC.ts';
import { cn } from '@/lib/utils.ts';

function AccountantCreatedSuccessfully() {
  const navigate = useNavigate();
  const { setBusinessCreatedSuccessFully } = useBusinessStore((state) => state);

  const onClickFinishButton = () => {
    navigate('/inicio');
  };

  const onClickRegisterButton = () => {
    setBusinessCreatedSuccessFully(BusinessCreationStatus.NOT_CREATED);
  };

  return (
    <div className="flex min-h-full items-start md:items-center justify-center w-full">
      <div className="text-center">
        <img className="inline-block" src={registerSuccessImg} alt="Imagen registro exitoso" />
        <h2 className="text-3xl	font-bold my-3">¡Listo!</h2>
        <h3 className="text-base">Tu empresa fue registrada correctamente.</h3>
        <hr className="my-5" />
        <div className="flex gap-3 text-left max-w-[411px] mb-10">
          <img src={exclamationIcon} alt="Img exclacion" />
          <p className="text-sm">
            Recuerda que podrás ver los datos procesados dentro de 1-2 días hábiles.
          </p>
        </div>
        <button
          type="button"
          className="primary-button-2 py-2 px-10 mb-5"
          onClick={() => onClickFinishButton()}
        >
          Finalizar
        </button>

        <p
          className="text-sm cursor-pointer text-[#1B68FF] font-semibold"
          onClick={() => onClickRegisterButton()}
        >
          Registrar otra empresa
        </p>
      </div>
    </div>
  );
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Ingrese el nombre de la empresa a registrar'),
  ruc: Yup.string()
    .required('Ingrese el RUC')
    .matches(/^\d+$/, 'El RUC solo debe contener números')
    .test('is-valid-ruc', 'El RUC ingresado es inválido', (value) => isValidRUC(value))
    .length(11, 'El RUC debe de tener 11 dígitos.'),
  username: Yup.string()
    .length(8, 'El usuario de SOL solo puede tener hasta 8 caracteres')
    .matches(/^[A-Z0-9]+$/, 'El usuario SOL solo debe contener letras mayúsculas y números')
    .required('Ingrese nombre de usuario'),
  passwordSOL: Yup.string().required('Ingrese su clave SOL'),
  // passwordConfirmation: Yup.string()
  //   .required('Confirma la contraseña SOL')
  //   .test('password-match', 'Las contraseñas no coinciden', function (value) {
  //     return value === this.parent.passwordSOL;
  //   }),
  terms: Yup.boolean().oneOf([true], ''),
  privacy: Yup.boolean().oneOf([true], ''),
});

function Form() {
  const business = useBusinessStore((state) => state.business);
  const setBusiness = useBusinessStore((state) => state.setBusiness);
  const setProfileSOL = useBusinessStore((state) => state.setProfileSOL);
  const createBusiness = useBusinessStore((state) => state.createBusiness);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    clearErrors,
    reset,
  } = useForm<RegisterBusinessForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  function onClickRegisterButton({ name, ruc, username, passwordSOL }: RegisterBusinessForm) {
    setBusiness({
      ...business,
      name,
      ruc,
    });

    setProfileSOL({
      username,
      passwordSOL,
    });

    createBusiness()
      .then(() => {
        console.log('Business created');
        eventGa4Service({
          action: CONTAPRO_ANALYTICS.BUSINESS_REGISTER_FORM.BUTTON_REGISTER.ACTION,
        });
      })
      .catch((_) => {
        console.error('Error creating business...');
        clearErrors();
        reset();
      });
  }

  return (
    <div className="flex min-h-full items-center justify-center w-full">
      <form
        onSubmit={handleSubmit(onClickRegisterButton)}
        method="POST"
        className="w-full max-w-lg"
      >
        <h1 className="text-center text-2xl font-bold mb-2">Registrar una nueva empresa</h1>
        <p className="text-center text-base mb-4">
          Ingresa los datos de tu cliente y visualiza todo el detalle contable.
        </p>
        <FormComponent register={register} errors={errors} />
        <Button id='btn-register' type="submit" className="w-full mt-4" disabled={!isValid}>
          Registrar
        </Button>
      </form>
    </div>
  );
}

function NewBusinessForm() {
  const accountantCreatedSuccessFully = useBusinessStore(
    (state) => state.businessCreatedSuccessFully,
  );
  const errorMessages: ErrorMessage[] = useBusinessStore((state) => state.errorMessages);

  return (
    <>
      {[BusinessCreationStatus.NOT_CREATED, BusinessCreationStatus.ERROR].includes(
        accountantCreatedSuccessFully,
      ) && (
        <div className="flex flex-col min-h-[90vh] items-center w-full">
          <Link className={cn("self-start", buttonVariants({ variant: 'ghost' }))} to="/">
            <ChevronLeftIcon size={18} /> Volver
          </Link>
          <div className="w-full mt-8">
            {accountantCreatedSuccessFully === BusinessCreationStatus.ERROR && (
              <ErrorAlert errorMessages={errorMessages} />
            )}
            <Form />
          </div>
          <CredicorpFooter />
        </div>
      )}

      {accountantCreatedSuccessFully === BusinessCreationStatus.CREATED && (
        <div className="flex min-h-[90vh] items-center justify-center">
          <AccountantCreatedSuccessfully />
        </div>
      )}
    </>
  );
}

export default NewBusinessForm;
