export const getDateFormatted = () => {
  const fechaActual = new Date();
  const dia = fechaActual.getDate();
  const opcionesMes: Intl.DateTimeFormatOptions = { month: 'long' };
  const mes = fechaActual.toLocaleString('es-ES', opcionesMes);
  const horas = fechaActual.getHours().toString().padStart(2, '0');
  const minutos = fechaActual.getMinutes().toString().padStart(2, '0');
  const zonaHoraria = fechaActual.getTimezoneOffset();
  const horasZona = Math.floor(Math.abs(zonaHoraria) / 60);
  const signoZona = zonaHoraria > 0 ? '-' : '+';
  const zonaFormateada = `GMT ${signoZona}${horasZona.toString().padStart(1)}`;

  return `Última actualización: ${dia} de ${mes} a las ${horas}:${minutos}, ${zonaFormateada}`;
};
