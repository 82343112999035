export interface Plan {
    id: number;
    title: string;
    subtitle: string;
    typePlan: string;
    prices: number;
    features: string[];
    recommended?: boolean;
}

export const plansPrices: Plan[] = [
    {
        id: 3,
        title: "Negocio pequeño",
        subtitle: "15 empresas",
        typePlan: 'Trimestral',
        prices: 477.90,
        features: [
            "Acceso a la plataforma y análisis de tus comprobantes del periodo.",
            "Observaciones, detracciones, percepciones y retenciones.",
            "Facturas de SIRE con items."
        ],
        recommended: false,
    },
    {
        id: 5,
        title: "Negocio pequeño",
        subtitle: "15 empresas",
        typePlan: 'Semestral',
        prices: 885,
        features: [
            "Acceso a la plataforma y análisis de tus comprobantes del periodo.",
            "Observaciones, detracciones, percepciones y retenciones.",
            "Facturas de SIRE con items."
        ],
        recommended: false,
    },
    {
        id: 7,
        title: "Negocio pequeño",
        subtitle: "15 empresas",
        typePlan: 'Anual',
        prices: 1770,
        features: [
            "Acceso a la plataforma y análisis de tus comprobantes del periodo.",
            "Observaciones, detracciones, percepciones y retenciones.",
            "Facturas de SIRE con items."
        ],
        recommended: false,
    },
    {
        id: 4,
        title: "Negocio emergente",
        subtitle: "30 empresas",
        typePlan: 'Trimestral',
        prices: 637.20,
        features: [
            "Acceso a la plataforma y análisis de tus comprobantes del periodo.",
            "Observaciones, detracciones, percepciones y retenciones.",
            "Facturas de SIRE con items."
        ],
        recommended: true,
    },
    {
        id: 6,
        title: "Negocio emergente",
        subtitle: "30 empresas",
        typePlan: 'Semestral',
        prices: 1180,
        features: [
            "Acceso a la plataforma y análisis de tus comprobantes del periodo.",
            "Observaciones, detracciones, percepciones y retenciones.",
            "Facturas de SIRE con items."
        ],
        recommended: true,
    },
    {
        id: 8,
        title: "Negocio emergente",
        subtitle: "30 empresas",
        typePlan: 'Anual',
        prices: 2360,
        features: [
            "Acceso a la plataforma y análisis de tus comprobantes del periodo.",
            "Observaciones, detracciones, percepciones y retenciones.",
            "Facturas de SIRE con items."
        ],
        recommended: true,
    },
    
];