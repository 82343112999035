import { useState } from "react";
import "./PlansPricesContapro.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Plan, plansPrices } from "@/shared/Plans";
import PlansSelector from "./components/PlansSelector";
import { useDashboardStore } from "@/store/DashboardStore";
import ic_badge_percent from "/assets/ic_badge_percent.svg?url";
import ic_check_circle from "/assets/ic_check_circle.svg?url";
import { formatNumber } from "./helpers";

interface PlanCardProps {
  title: string;
  subtitle: string;
  typePlan: string;
  prices: number;
  features: string[];
  recommended?: boolean;
  setSelectedPlan: () => void;
}

const PlanCard: React.FC<PlanCardProps> = ({
  title,
  subtitle,
  prices,
  features,
  recommended,
  setSelectedPlan,
}) => {
  return (
    <div className="w-[405px] border border-gray-300 block pt-11 pb-8 px-6 bg-white rounded-lg shadow-md mx-2 relative">
      {recommended && (
        <div className="absolute top-6 left-6 transform -translate-y-1/2">
          <span className="recommended font-bold">Recomendado</span>
        </div>
      )}
      <h3 className="text-xl font-bold">{title}</h3>
      <h4 className="text-lg mt-2">
        Registra hasta <b>{subtitle}</b>
      </h4>
      <h1 className="text-4xl font-semibold mt-4">S/. {formatNumber(prices)}</h1>
      <button
        onClick={setSelectedPlan}
        className="my-5 rounded-[20px] f-secondary font-medium text-base mb-3 sm:mb-0 px-5 py-2 bg-white border border-gray-800 hover:bg-gray-800 hover:text-white hover:border-gray-800 transition"
        type="button"
      >
        Elegir plan
      </button>
      <hr className="my-4" />
      <div>
        <p className="font-semibold">Que incluye:</p>
        <ul className="list-disc list-inside mt-2">
          {features.map((feature, index) => (
            <li key={index} className="text-gray-700 flex items-center">
              <img src={ic_check_circle} alt="Imagen de check" className="w-4 my-1 mr-4" />
              <span className="text-sm font-medium leading-[1.1] my-1">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const PlansPricesContapro = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedPeriod, setSelectedPeriod] = useState("Trimestral");
  const [plans] = useState<Plan[]>(plansPrices);
  const { setSelectedPlan } = useDashboardStore((state) => state);

  const filteredPlans = plans.filter((plan) => plan.typePlan === selectedPeriod);
  const planType = location.state?.planType;
  console.log("plan recibido en planes y precios", planType);
  const handleClick = () => {
    navigate("/inicio");
  };

  const handleSelectedPlan = (planId: number) => {
    setSelectedPlan(planId);
    navigate("/pago");
  };

  return (
    <div className="bg-[#F7FBFF] min-h-screen p-4 md:py-10 md:p-6">
      <div className="text-center">
        <h1 className="text-[28px] font-bold">Escoge el plan ideal para ti</h1>
      </div>
      {planType !== 1 && (
        <div className="rounded-lg px-6 py-5 flex gap-4 m-12 banner">
          <div className="flex-none w-10 h-auto">
            <img src={ic_badge_percent} alt="ContaPro" className="w-16 h-auto" />
          </div>
          <div>
            <p className="text-lg font-bold mb-2">¡Inicia con tu prueba gratis!</p>
            <p className="text-sm mb-3 f-secondary font-normal">
              Tienes <b>7 días</b> de prueba gratis. Para registrar más empresas y recibir toda la
              información escoge uno de nuestros planes.
            </p>
            <button
              className="rounded-[20px] f-secondary font-medium text-base mb-3 sm:mb-0 px-5 py-2 bg-white border border-stone-950 hover:border-stone-900"
              onClick={handleClick}
              type="button"
            >
              Empezar prueba gratis
            </button>
          </div>
        </div>
      )}

      <PlansSelector onSelectPeriod={setSelectedPeriod} period={selectedPeriod} />

      <div className="flex justify-center flex-wrap mt-12">
        {filteredPlans.map((plan) => (
          <PlanCard
            key={plan.id}
            title={plan.title}
            typePlan={plan.typePlan}
            subtitle={plan.subtitle}
            prices={plan.prices}
            features={plan.features}
            recommended={plan.recommended}
            setSelectedPlan={() => handleSelectedPlan(plan.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default PlansPricesContapro;