import { useScript } from "@/hooks/use-script";
import { lazy, Suspense, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "lucide-react";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import logoIcon from "/assets/logo-contapro--regular.png?url";
import { useDashboardStore } from "@/store/DashboardStore";
import { plansPrices } from "@/shared/Plans";
import Loader from "@/Loader";
import ModalTokenExpired from "../ModalTokenExpired";
import { useAuthStore } from "@/store/AuthStore";

const LazyPayment = lazy(() => import("./Payment"));

export const PaymentWrapper = () => {
    const { loaded, error } = useScript("https://checkout.culqi.com/js/v4");
    const tokenExpired = useAuthStore((state) => state.accessTokenExpired);
    const navigate = useNavigate();
    const { selectedPlan } = useDashboardStore(state => state);

    useEffect(() => {
        if (!plansPrices.find(plan => plan.id === selectedPlan)) {
            navigate('/inicio');
        }
    }, [selectedPlan])


    return (
        <div className="bg-white min-h-screen w-full">
            <Loader />
            <div className="container mx-auto">
                <div className="p-4 mb-6">
                    <Link to="/inicio"><img src={logoIcon} alt="Somos contapro" /></Link>
                </div>
                <Link className={cn(buttonVariants({ variant: 'linkSecondary' }), "px-4")} to="/inicio">
                    <ChevronLeftIcon size={25} /> Volver
                </Link>
                {
                    error ?
                        <div>Ocurrió un error...</div> :
                        <Suspense fallback={<div>Cargando componente de pago...</div>}>
                            {loaded && <LazyPayment />}
                        </Suspense>
                }
            </div>
            {tokenExpired && <ModalTokenExpired showModal={true} />}
        </div>
    );
};

export default PaymentWrapper