import GraphicCard from "./GraphicCard.tsx";
import { COLOR_FACTURAS, COLOR_BOLETAS, formatNumber, COLOR_NOTAS_CREDITO, COLOR_NOTAS_DEBITO } from "./helpers.ts";
import ProcessingDataCard from "./ProcessingDataCard.tsx";
import Card from '../shared/CardInfo.tsx';
import ic_metric_up_green from '/assets/ic_metric_up_green.svg?url'
import ic_metric_down from '/assets/ic_metric_down.svg?url'
import sp_ticket_check_e from "/assets/sp_ticket_check_e.svg?url";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";

interface TransactionCardProps {
    title: string;
    content: React.ReactNode;
    titleClass?: string;
    containerClass?: string;
    footerTitle?: string;
    footerValue?: React.ReactNode;
}

interface FormatNumber {
    (number: number): string;
}

const TransactionCard: React.FC<TransactionCardProps> = ({
    title,
    content,
    titleClass = "text-gray-800 font-semibold text-lg mb-3",
    containerClass = "flex gap-1",
    footerTitle = "Importe total",
    footerValue,
}) => {
    const footerValueNumeric = typeof footerValue === 'number' 
        ? footerValue 
        : parseFloat(footerValue?.toString() || '0');

    const isNegative = footerValueNumeric < 0;
    const isPositive = footerValueNumeric > 0;
    const footerValueColor = isNegative ? "text-red-600" : isPositive ? "text-green-600" : "";

    const footerImage = isNegative ? ic_metric_down : ic_metric_up_green;

    return (

    <Card className="w-full border border-opacity-70 border-neutral-light rounded-2xl p-11 shadow-sm bg-white">        
        <Card.Body>
            <p className={titleClass}>{title}</p>
            <div className={containerClass}>
                <div className="text-2xl font-semibold text-primary-black">{content}</div>
                {footerValueNumeric !== 0 && (
                    <div
                        className={`font-semibold flex items-center ${footerValueColor}`}
                    >
                        {footerValue}
                        <img src={footerImage} alt="Ícono_arrow"/>*
                        
                        
                    </div>
                )}
            </div>
            <div className="text-gray-900 text-[10px] py-2 leading-none	">
                * Valor con respecto al mes anterior.
            </div>
            {footerTitle && (
                <div className="text-sm text-gray-500 mt-2">
                    {footerTitle}
                </div>
            )}
        </Card.Body>
    </Card>
)};

const CardAvailableData = ({
    title,
    typeCard,
    businessInfo,
    direction = "column",
    chartType = "pie",
    history_total_amount,
    showButtonDetails = false,
}: {
    history_total_amount: HistoryTotalAmount;
    title: string;
    businessInfo?: Business;
    typeCard: string;
    direction?: "row" | "column";
    amountText?: string;
    showButtonDetails?: boolean;
    chartType?: "pie" | "areaSales";
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const onClickDetails = (businessInfo: Business | undefined ) => {
        if (!businessInfo) {
            return;
        }

        const currentPath = location.pathname;
        const newUrl = `${currentPath}/observaciones`;
        navigate(newUrl, {
            state: {
                page: 'observaciones',
                ...businessInfo
            },
        });
    };
    
    return (
        <GraphicCard
            title={title}
            showTitleIcon={true}
            iconTitle=''
            data={[]}
            history_total_amount={history_total_amount}
            direction={direction}
            textCenterLower="Monto de IGV"
            cssClassWrapper={`sm:flex gap-4 ${typeCard === 'billsObserved' ? 'flex-row-reverse' : 'flex-col'}`}
            chartType={chartType}
        >
            {
                showButtonDetails &&
                    <div 
                        className="db__card--link cursor-pointer flex gap-2 items-center mt-3"
                        onClick={() => onClickDetails(businessInfo)}>
                        <span>{chartType === 'pie' ? 'Gestionar comprobantes observados' : 'Ver todos mis comprobantes'}</span>
                        <img src="/assets/ic_arrow_right.svg" alt="Ir" />
                    </div>
            }
            
        </GraphicCard>
        
    );
};


function Sales({ sales, history_total_amount, businessInfo }: 
        {   sales: Sales; 
            businessInfo: Business; 
            currencyCode?: string;
            history_total_amount: HistoryTotalAmount;

        }) {
    const data = [
        {
            name: "Facturas",
            value: 1,
            totalValue: sales.num_bills ?? 0,
            amountIGV: sales.igv_bills,
            color: COLOR_FACTURAS,
        },
        {
            name: "Boletas",
            value: 1,
            totalValue: sales.num_tickets ?? 0,
            amountIGV: sales.igv_tickets,
            color: COLOR_BOLETAS,
        },
        {
            name: "Notas de crédito",
            value: sales.num_credit_notes ?? 0,
            totalValue: sales.num_credit_notes ?? 0,
            amountIGV: sales.igv_credit_notes,
            color: COLOR_NOTAS_CREDITO,
        },
        {
            name: "Notas de débito",
            value: sales.num_debit_notes ?? 0,
            totalValue: sales.num_debit_notes ?? 0,
            amountIGV: sales.igv_debit_notes,
            color: COLOR_NOTAS_DEBITO,
        },
    ];


    const formatNumbeDecimal: FormatNumber = (number) => {
        return number
            .toFixed(2) 
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    const valueFormattedDecimal = formatNumbeDecimal(sales.total_amount ?? 0);

    const cardData = [
        {
            title: "Ventas del mes",
            content: `S/ ${valueFormattedDecimal}`,
            footerTitle: "Importe total",
            footerValue: `${sales.porcentage_previous_month?.porcentage_total_sales.toFixed(2) ?? 0}%`,
        },
        {
            title: "Comprobantes emitidos",
            content: sales.total_cpes_count ?? 0,
            footerTitle: "Importe total",
            footerValue: `${sales.porcentage_previous_month?.porcentage_cps_count ?? 0}%`,
        },
    ];

    const title = "Tipos de comprobantes emitidos";
    const chartType = "pie";
    const totalSum = data.reduce((acc, item) => acc + item.totalValue, 0);


    return (
        <>
        {
            totalSum === 0 ? (
                <div className="flex flex-col items-center justify-center text-center mt-10 mb-40">
                    <img src={sp_ticket_check_e} alt="Sin comprobantes" className="w-20 h-20 mb-4" />
                    <p className="text-lg font-bold mb-2">No hay comprobantes de ventas en el periodo</p>
                    <p className="text-sm">Seleccione otro periodo en donde hayas registrado comprobantes.</p>
                </div>
            ):(
                <div className="mt-6 mb-4 lg:mb-3">
                    <div className="flex flex-col-reverse md:flex-row gap-4 mb-4">
                        <div className="space-y-4 w-full lg:w-2/5">
                            {cardData.map((card, index) => (
                                <TransactionCard
                                    key={`${card.title}-${index}`}
                                    title={card.title}
                                    content={card.content}
                                    footerTitle={card.footerTitle}
                                    footerValue={card.footerValue}
                                />
                            ))}
                        </div>
                        <div id="step-three" className="w-full md:w-3/5 mt-4 md:mt-0 overflow-x-auto">
                            {
                                    <CardAvailableData
                                        title="Evolución de las ventas"
                                        typeCard=""
                                        history_total_amount={history_total_amount}
                                        businessInfo={businessInfo}
                                        direction="row"
                                        showButtonDetails={true}
                                        chartType="areaSales"
                                    ></CardAvailableData>
                            }
                        </div>
                    </div>           
                    <div className="flex flex-col-reverse md:flex-row gap-4 mb-">
                        <div className="w-full lg:w-2/5">
                            {sales.num_bills === 0 && sales.num_tickets === 0 ? (
                                <ProcessingDataCard
                                    title="No hay ventas por mostrar"
                                    subtitle="No hemos encontrado ventas en el periodo."
                                />
                            ) : (
                                <GraphicCard
                                    title={title}
                                    showTitleIcon={false}
                                    iconTitle={""}
                                    data={data}
                                    history_total_amount={history_total_amount}
                                    direction="column"
                                    textCenterUpper={`s/ ${formatNumber(sales.igv_amount_total)}`}
                                    textCenterLower="Monto de IGV"
                                    cssClassWrapper="sm:flex gap-4 2xl:grid grid-cols-5"
                                    chartType={chartType}
                                    
                                >
                                    <GraphicCard.CardInfo>
                                        <div className="legend">
                                            <div className="legend-detail">
                                            {data.map((item, index) => (
                                                <div key={`card-${index}`} >
                                                    <div
                                                        id={`legend_${index}_${item.name}`}
                                                        className="border-none border-transparent"
                                                    >
                                                        <div className="flex justify-between gap-2 mb-4">
                                                            <div className="text-left flex gap-2">
                                                                <div className="font-normal text-sm">
                                                                    <div className="flex align-middle mb-1">
                                                                        <svg
                                                                            className="margin-auto"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="12"
                                                                            height="12"
                                                                            viewBox="0 0 12 12"
                                                                            fill="none"
                                                                        >
                                                                            <circle cx="6" cy="6" r="6" fill={item.color} />
                                                                        </svg>
                                                                        <p className="pl-2 text-xs font-semibold">{item.name}</p>
                                                                    </div>
                                                                    <p className="pl-5 text-xs font-normal">Monto de IGV</p>
                                                                </div>
                                                            </div>
        
                                                            <div className="text-right block">
                                                                <p className="text-xs font-bold mb-1">{item.totalValue}</p>
                                                                <p className="text-xs font-semibold">S/ {formatNumber(item.amountIGV)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {index !== 4 && (
                                                        <hr className="border-solid border-with-2 border-[#C7CADD] my-2 w-full" />
                                                    )}
                                                </div>
                                            ))}
                                            </div>
                                        </div>
                                    </GraphicCard.CardInfo>
                                    
                                </GraphicCard>
                            )}
                        </div>
                    </div>           
                </div>
    
            )
        }
        </>
    );
}

export default Sales;
