import { useState, useEffect } from 'react';

const useRecaptcha = (recaptchaToken: string) => {
    const [getRecaptcha, setGetRecaptcha] = useState(false);
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/enterprise.js?render=${recaptchaToken}`;
        script.async = true;

        script.onload = () => {
            if (window.grecaptcha) {
                window.grecaptcha.enterprise.ready(() => {
                    setGetRecaptcha(true);
                });
            } else {
                console.error('no está disponible reCAPTCHA');
            }
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
            const badge = document.querySelector('.grecaptcha-badge');
            badge?.remove();
        };
    }, [recaptchaToken]);

    const executeRecaptcha = async (action: string): Promise<string | null> => {
        if (getRecaptcha && window.grecaptcha) {
            try {
                const token = await window.grecaptcha.enterprise.execute(recaptchaToken, { action });
                setCaptchaToken(token);
                return token;
            } catch (error) {
                console.error('Error executing reCAPTCHA:', error);
                return null;
            }
        }
        return null;
    };

    return { executeRecaptcha, captchaToken, getRecaptcha };
};

export default useRecaptcha;
