import React, { ReactNode, useState } from 'react';
import screenshotRUC from '/assets/captura-sunat-ruc.png';
import screenshotUser from '/assets/captura-sunat-user.png';
import screenshotPassword from '/assets/captura-sunat-password.png';
import { CircleHelp} from 'lucide-react';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

import { Input } from '@/components/ui/input';
import { FormItem, FormItemMessage } from '@/components/ui/form-item';

const URL_TYC = 'https://www.somoscontapro.com/terminos-y-condiciones';
const URL_POLICY = 'https://www.somoscontapro.com/politicas-de-privacidad';

interface FormInputsProps {
  register: any;
  errors: any;
  isEdit?: boolean;
}

interface TooltipProps {
  children: ReactNode;
  open: boolean;
  onMouseOver(): void;
  onMouseLeave(): void;
}

const SimpleTooltip = ({ children, open, onMouseOver, onMouseLeave }: TooltipProps) => (
  <TooltipProvider>
    <Tooltip open={open}>
      <TooltipTrigger onMouseEnter={onMouseOver} onMouseLeave={onMouseLeave}>
        <CircleHelp className="text-dark-gray" />
      </TooltipTrigger>
      <TooltipContent className="max-w-72" side="right">
        {children}
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

type TooltipInput = 'username' | 'ruc' | 'password';

const FormComponent: React.FC<FormInputsProps> = ({ register, errors, isEdit }) => {
  const [openedTooltip, setOpenedTooltip] = useState<TooltipInput | null>(null);
  const [showPassword] = useState(false);

  const handleInputFocus = (name: TooltipInput) => {
    setOpenedTooltip(name);
  };

  const handleInputBlur = () => {
    setOpenedTooltip(null);
  };

  return (
    <>
      <FormItem>
        <Input
          id='name-businesses'
          {...register('name')}
          placeholder="Nombre de la empresa"
          variant={errors.name && 'error'}
          disabled={isEdit}
        />
        {errors.name && <FormItemMessage error>{errors.name?.message}</FormItemMessage>}
      </FormItem>
      <FormItem>
        <Input
          id='ruc-business'
          maxLength={11}
          {...register('ruc', {
            onBlur: handleInputBlur,
          })}
          placeholder="RUC"
          variant={errors.ruc && 'error'}
          onFocus={() => handleInputFocus('ruc')}
          disabled={isEdit}
          icon={
            <SimpleTooltip
              open={openedTooltip === 'ruc'}
              onMouseOver={() => handleInputFocus('ruc')}
              onMouseLeave={handleInputBlur}
            >
              <img src={screenshotRUC} className="w-full" />
              <p className="text-xs text-center mt-2">
                RUC de la empresa de tu cliente. Debe tener 11 números.
              </p>
            </SimpleTooltip>
          }
        />
        {errors.ruc && <FormItemMessage error>{errors.ruc?.message}</FormItemMessage>}
      </FormItem>
      <FormItem>
        <Input
          id='user-sunat'
          autoComplete='off'
          maxLength={8}
          {...register('username', {
            onBlur: handleInputBlur,
          })}
          placeholder="Usuario Portal SOL"
          variant={errors.username && 'error'}
          onFocus={() => handleInputFocus('username')}
          icon={
            <SimpleTooltip
              open={openedTooltip === 'username'}
              onMouseOver={() => handleInputFocus('username')}
              onMouseLeave={handleInputBlur}
            >
              <img src={screenshotUser} className="w-full" />
              <p className="text-xs text-center mt-2">
                Usuario con el que ingresas al Portal SOL. Máximo 8 caracteres.
              </p>
            </SimpleTooltip>
          }
        />
        {errors.username && <FormItemMessage error>{errors.username?.message}</FormItemMessage>}
      </FormItem>
      <FormItem>
        <Input
          id='password-sunat'
          autoComplete='off'
          maxLength={12}
          type={!showPassword && 'password'}
          placeholder="Clave SOL"
          variant={errors.passwordSOL && 'error'}
          onFocus={() => handleInputFocus('password')}
          {...register('passwordSOL', {
            onBlur: handleInputBlur,
          })}
          icon={
            <SimpleTooltip
              open={openedTooltip === 'password'}
              onMouseOver={() => handleInputFocus('password')}
              onMouseLeave={handleInputBlur}
            >
              <img src={screenshotPassword} className="w-full" />
              <p className="text-xs text-center mt-2">
                Contraseña con la que ingresas al Portal SOL.
              </p>
            </SimpleTooltip>
          }
        />
        {errors.passwordSOL && (
          <FormItemMessage error>{errors.passwordSOL.message}</FormItemMessage>
        )}
      </FormItem>
      {!isEdit &&
        <div className="flex align-middle mt-5">
          <input type="checkbox" id="terms" {...register('terms')} />
          <label className="w-full pl-1 cursor-pointer" htmlFor="terms">
            Acepto los{' '}
            <a href={URL_TYC} target="_blank" className="link">
              términos y condiciones
            </a>
          </label>
        </div>}
      {!isEdit &&
        <div className="flex align-middle mt-2">
          <input type="checkbox" id="privacy" {...register('privacy')} />
          <label className="w-full pl-1 cursor-pointer" htmlFor="privacy">
            Acepto las{' '}
            <a href={URL_POLICY} target="_blank" className="link">
              políticas de privacidad
            </a>
          </label>
        </div>
      }
      
    </>
  );
};

export default FormComponent;


