import { useLocation, useNavigate } from "react-router-dom";
import CardSmall from "../shared/CardSmall.tsx";
import GraphicCard from "./GraphicCard.tsx";
import { COLOR_FACTURAS, COLOR_BOLETAS, COLOR_3, formatNumber } from "./helpers.ts";
import ProcessingDataCard from "./ProcessingDataCard.tsx";
import copyIcon from "/assets/ic_copy.svg?url";
import Card from '../shared/CardInfo.tsx';
const COLOR_OBSERVED_REASONS = [COLOR_FACTURAS, COLOR_BOLETAS, COLOR_3];
import icValidadas from "/assets/ic_document_approved_black.svg?url";
import icObservadas from "/assets/ic_document_approved_black.svg?url";
import sp_ticket_check_e from "/assets/sp_ticket_check_e.svg?url";
import icClock from "/assets/ic_clock_black.svg?url";
import spc_clock_hand_d from "/assets/spc_clock_hand_d.svg?url";
import icQuestionGray from "/assets/ic_question_circle--gray.svg?url";
import classNames from 'classnames';
import { useState } from 'react';
import DetractionsCard from "./components/DetractionsCard.tsx";
import { ModalState } from "@/shared/Modal.tsx";

interface DataGraphic {
    key: number;
    name: string;
    value: number;
    totalValue: number;
    color: string;
    amount?: string;
}

interface StatusBills extends DataGraphic {
    icon: string;
    colorIcon: string;
    type: string;
    showTooltip: boolean;
}

interface TotalSumCard {
    [key: string]: number;
}

const CardAvailableData = ({
    purchase,
    dataGraphic,
    title,
    businessInfo,
    typeCard,
    direction = "column",
    showButtonDetails = false,
    chartType = "pie",
    history_total_amount,   
}: {
    purchase: Purchase;
    history_total_amount: HistoryTotalAmount;
    dataGraphic: DataGraphic[];
    title: string;
    businessInfo?: Business;
    typeCard: string;
    direction?: "row" | "column";
    amountText?: string;
    showButtonDetails?: boolean;
    chartType?: "pie" | "areaPurchase";
}) => {
    const totalSumCard: TotalSumCard = {
        ['billsObserved']: purchase.num_observed,
        ['totalBills']: purchase.total_cpes_count
    }
    const navigate = useNavigate();
    const location = useLocation();
    const onClickDetails = (businessInfo: Business | undefined ) => {
        if (!businessInfo) {
            return;
        }

        const currentPath = location.pathname;
        const newUrl = `${currentPath}/observaciones`;
        navigate(newUrl, {
            state: {
                page: 'observaciones',
                ...businessInfo
            },
        });
    };
    
    const dataFiltered = dataGraphic.filter((item) => item.totalValue > 0);
    const valueLabelGraphic = title.includes('observadas') ? purchase.igv_amount_observed : purchase.igv_amount_total;

    return (
        <GraphicCard
            title={title}
            showTitleIcon={true}
            iconTitle={icObservadas}
            data={dataFiltered}
            history_total_amount={history_total_amount}
            direction={direction}
            textCenterUpper={`S/ ${formatNumber(valueLabelGraphic)}`}
            textCenterLower="Monto de IGV"
            cssClassWrapper={`sm:flex gap-4 ${typeCard === 'billsObserved' ? 'flex-row-reverse' : 'flex-col'}`}
            chartType={chartType}
        >
            <GraphicCard.CardInfo>
                <div id="legend" >
                    <div id="legend_detail" >
                        {dataGraphic.map((item, index) => (
                            <div
                                key={`card-${index}`}
                                id={`legend_${index}_${item.name}`}
                                className="border-none border-transparent">
                                {index !== 0 && (
                                    <hr className="border-solid border-with-2 border-[#C7CADD] my-2 w-full" />
                                )}
                                {/* <div className="flex justify-between gap-2">
                                    <div className="text-left flex gap-2">
                                        <div className="font-normal text-sm">
                                            <div className="flex align-middle mb-1">
                                                <svg
                                                    className="h-auto flex-none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="12"
                                                    viewBox="0 0 12 12"
                                                    fill="none">
                                                    <circle cx="6" cy="6" r="6" fill={item.color} />
                                                </svg>
                                                <p className="pl-2 f-secondary text-base font-medium">{item.name}</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="text-right block">
                                        <p className="text-sm font-semibold mb-1">{item.totalValue}</p>
                                        <p className="text-xs font-semibold">{`s/${item.amount}`}</p>
                                    </div>
                                </div> */}
                            </div>
                        ))}
                    </div>
                    {chartType === "pie" && (
                        <div className="legend-total">
                        <hr className="border-solid border-with-2 border-[#C7CADD] w-full my-4" />
                        <div className="flex items-center justify-between gap-x-24">
                            <div className="flex gap-2 content-center items-center">
                                <div>
                                    <p className="text-sm font-semibold">Total</p>
                                </div>
                            </div>

                            <div className="text-right flex items-center">
                                <p className="text-sm font-semibold pr-2">{totalSumCard[typeCard]}</p>
                                <img src={copyIcon} alt="Total" />
                            </div>
                        </div>
                    </div>
                    )}
                    
                </div>
            </GraphicCard.CardInfo>

            {
                showButtonDetails &&
                <div
                    className="db__card--link cursor-pointer flex gap-2 items-center mt-3"
                    onClick={() => onClickDetails(businessInfo)}>
                    <span>{chartType === 'pie' ? 'Gestionar comprobantes observados' : 'Ver todos mis comprobantes'}</span>
                    <img src="/assets/ic_arrow_right.svg" alt="Ir" />
                </div>
            }
        </GraphicCard>
    );
};

function Purchases(
    { dashboardData, purchase, businessInfo, setModalOpenDownloadExcel }:
    {
        dashboardData: DashboardData;
        purchase: Purchase;
        businessInfo: Business;
        currencyCode?: string;
        setModalOpenDownloadExcel: (newState: ModalState) => void;
    }) {
    const [showTooltip, setShowTooltip] = useState(false);
    const navigate = useNavigate();
    const { sum_retention, sum_perception, detractions } = dashboardData;
    const { sum_paid_detractions, sum_not_paid_detractions } = detractions; 
    const { history_total_amount } = dashboardData;    
 
    const data: StatusBills[] = [
        {
            key: 1,
            name: "Comprobantes validados",
            icon: icValidadas,
            colorIcon: 'bg-[#82FFCA]',
            type: 'deductibles',
            showTooltip: false,
            value: purchase.porc_deductibles ?? 0,
            totalValue: purchase.num_deductibles ?? 0,
            color: COLOR_FACTURAS,
            amount: formatNumber(purchase.igv_amount_deductibles) ?? 0,
        },
        {
            key: 2,
            name: "Comprobantes observados",
            icon: icObservadas,
            colorIcon: 'bg-salmon',
            type: 'observed',
            showTooltip: false,
            value: purchase.porc_observed ?? 0,
            totalValue: purchase.num_observed ?? 0,
            color: COLOR_3,
            amount: formatNumber(purchase.igv_amount_observed) ?? 0,
        },
        {
            key: 3,
            name: "En proceso de análisis",
            icon: icClock,
            colorIcon: 'bg-[#E7F6FF]',
            type: 'processing',
            showTooltip: true,
            value: purchase.porc_not_processed ?? 0,
            totalValue: purchase.num_not_processed ?? 0,
            color: COLOR_BOLETAS,
            amount: '',
        },
    ];
    const totalSumDeductibles = data.reduce((sum, item) => sum + item.totalValue, 0);
    const dataGraphLower: DataGraphic[] = purchase.observed_reasons.map((reason, index) => {
    
    
        return {
            key: index + 1,
            name: reason.reason,
            value: reason.amount,
            totalValue: reason.amount,
            color: COLOR_OBSERVED_REASONS[index],
            amount: formatNumber(reason.igv),
        };
    });

    const linkToRetentions = () => {
        const currentPath = location.pathname;
        navigate(`${currentPath}/retenciones`, {
            state: {
                page: 'retenciones',
                ...businessInfo
            },
        });
    }

    const linkToPercepciones = () => {
        const currentPath = location.pathname;
        navigate(`${currentPath}/percepciones`, {
            state: {
                page: 'compras-percepciones',
                ...businessInfo
            }
        });
    }

    const linkToDetracciones = () => {
        const currentPath = location.pathname;
        navigate(`${currentPath}/detracciones`, {
            state: {
                page: 'detracciones',
                ...businessInfo
            }
        });
    }

    const linkToDeductibles = () => {
        const currentPath = location.pathname;
        navigate(`${currentPath}/facturas-validadas`, {
            state: {
                page: 'facturas-validadas',
                ...businessInfo
            }
        });
    }

    const linkToObserved = () => {
        const currentPath = location.pathname;
        navigate(`${currentPath}/observaciones`, {
            state: {
                page: 'observaciones',
                ...businessInfo
            },
        });
    }

    const handleOpenModalDownloadExcel = () => {
        setModalOpenDownloadExcel(ModalState.OPEN);
    };

    return (
        <>
        {
            totalSumDeductibles === 0 ? (
                <div className="flex flex-col items-center justify-center text-center mt-10 mb-40">
                    <img src={sp_ticket_check_e} alt="Sin comprobantes" className="w-20 h-20 mb-4" />
                    <p className="text-lg font-bold mb-2">No hay comprobantes de compras en el periodo</p>
                    <p className="text-sm">Seleccione otro periodo en donde hayas registrado comprobantes.</p>
                </div>
            ):(
                <>
                    {dashboardData.purchase.num_deductibles === 0 && (
                        <div className="rounded-lg px-6 py-5 flex gap-4 bg-[#E8F0FF] my-8">
                            <div className="flex-none">
                                <img src={spc_clock_hand_d} alt="ContaPro" className='w-[100px]'/>
                            </div>
                            <div className="">
                                <p className="text-lg font-bold mb-2">Estamos analizando tus comprobantes</p>
                                <p className="text-sm mb-3 f-secondary font-normal">Puedes ver todo el detalle de los comprobantes descargándolos, y mañana los podrás ver en Contapro.</p>
                                <a
                                    className="flex text-sm text-primary-blue-2 font-bold f-secondary cursor-pointer"
                                    type="button"
                                    onClick={handleOpenModalDownloadExcel}
                                >Descargar todos los comprobantes sin análisis<img src="/assets/ic_arrow_right.svg" alt="Ver comprobantes validados" className="ml-1"/></a>
                            </div>
                        </div>
                    )}
                    <div className="my-6">
                        <div className="block md:flex gap-4 mb-4 lg:mb-6">
                            <Card className="w-full md:w-2/5">
                                <Card.Body>
                                    <p className="font-semibold text-xl text-[#15161C] mb-4">Estado de tus comprobantes</p>
                    
                                    {
                                        data.map((item, index) => (
                                            <div key={index}>
                                                {index !== 0 && (
                                                    <hr className="border-solid border-with-2 border-[#C7CADD] my-4 w-full" />
                                                )}
                                                <div
                                                    className="flex justify-between items-center gap-4 relative"
                                                >
                                                    <div className="flex-none">
                                                        <div
                                                            className={classNames(
                                                                "w-14 h-14 flex items-center justify-center rounded-full",
                                                                item.colorIcon
                                                            )}
                                                        >
                                                            <img src={ item.icon } alt="Icono tarjeta"/>
                                                        </div>
                                                    </div>
                                                    <div className="flex-auto">
                                                        <div className="flex justify-between">
                                                            <p className="f-secondary text-base font-medium flex items-center gap-2">
                                                                {item.name}
                                                                {
                                                                    item.showTooltip && (
                                                                        <>
                                                                            <img
                                                                                src={icQuestionGray}
                                                                                alt="Icono_question"
                                                                                className="w-4 h-auto inline-block cursor-pointer"
                                                                                onMouseEnter={() => setShowTooltip(true)}
                                                                                onMouseLeave={() => setShowTooltip(false)}
                                                                            />
                                                                            {showTooltip && (
                                                                                <div
                                                                                    className="absolute shadow-md border w-60 border-gray-100 left-0 md:left-16 top-8 md:top-6 z-10 p-2 text-xs font-normal leading-tight text-black bg-white rounded whitespace-no-wrap tooltip f-secondary">
                                                                                    <div className="absolute boder border-gray-100 w-3 h-3 bg-white transform rotate-45 -top-1.5 left-[170px]"></div>
                                                                                    Los comprobantes en proceso todavía no han sido analizados por Contapro para detectar observaciones.
                                                                                </div>
                                                                            ) }
                                                                        </>
                                                                    )
                                                                }
                                                            </p>
                                                            <p className="text-xl font-normal f-secondary">{
                                                                item.type !== 'processing' ?
                                                                    item.totalValue : ''
                                                            }</p>
                                                        </div>
                                                        <p className="text-xl font-semibold">{
                                                            item.type === 'processing' ?
                                                                item.totalValue : `s/${ item.amount }`
                                                        }</p>
                                                        {index === 0 && (
                                                            <a onClick={() => linkToDeductibles()} className="flex mt-2 text-sm text-primary-blue-2 font-bold f-secondary cursor-pointer">Ver comprobantes validados <img src="/assets/ic_arrow_right.svg" alt="Ver comprobantes validados" className="ml-1"/></a>
                                                        )}
                                                        {index === 1 && (
                                                            <a onClick={() => linkToObserved()} className="flex mt-3 text-sm text-primary-blue-2 font-bold f-secondary cursor-pointer">Gestionar comprobantes observados<img src="/assets/ic_arrow_right.svg" alt="Ver comprobantes validados" className="ml-1"/></a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <hr className="border-solid border-with-2 border-[#C7CADD] my-4 w-full" />
                                    <div className="mt-4">
                                        <div className="flex justify-between">
                                            <p className="text-base font-bold">Total de comprobantes</p>
                                            <p className="text-xl f-secondary">{totalSumDeductibles}</p>
                                        </div>

                                    </div>
                                </Card.Body>
                            </Card>

                            <div id="step-three" className="w-full md:w-3/5 mt-4 md:mt-0 overflow-x-auto">
                                {
                                    
                                        <CardAvailableData
                                            title="Evolución de las compras"
                                            typeCard=""
                                            purchase={purchase}
                                            history_total_amount={history_total_amount}
                                            dataGraphic={dataGraphLower}
                                            direction="row"
                                            businessInfo={businessInfo}
                                            showButtonDetails={true}
                                            chartType="areaPurchase"
                                        ></CardAvailableData>
                                    
                                }
                            </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div>
                                <div className="">
                                    <DetractionsCard 
                                        caption="Detracciones" 
                                        detractionPaid={sum_paid_detractions}
                                        detractionNotPaid={sum_not_paid_detractions} 
                                        linkTo={linkToDetracciones}
                                    /> 
                                </div>

                                <div className="w-full flex gap-4 mb-4 lg:mb-0 pt-6">
                                    <div className="bg-white h-auto w-full lg:w-[50%] max-sm:w-1/2">
                                    <CardSmall
                                        primaryNumber={sum_perception}
                                        caption="Percepciones"
                                        reverse={true}
                                        linkTo={linkToPercepciones}
                                    />
                                    </div>
                                    <div className="bg-white h-full w-full lg:w-[50%] max-sm:w-1/2">
                                        <CardSmall primaryNumber={sum_retention} caption="Retenciones" reverse={true} linkTo={linkToRetentions} />
                                    </div>
                                </div>
                            </div>
                            <div id="step-three" className="w-full mt-4 md:mt-0">
                                {
                                    purchase.observed_reasons.length === 0 ? (
                                        <ProcessingDataCard
                                            title="No hay comprobantes observados"
                                            subtitle="No hemos encontrado comprobantes observados en el periodo."
                                        />
                                    ) : (
                                        <CardAvailableData
                                            title="Comprobantes observados"
                                            typeCard="billsObserved"
                                            purchase={purchase}
                                            history_total_amount={history_total_amount}
                                            dataGraphic={dataGraphLower}
                                            direction="row"
                                            businessInfo={businessInfo}
                                            showButtonDetails={true}
                                            chartType="pie"
                                        ></CardAvailableData>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </>
        )
        }
        </>
    );
}

export default Purchases;