import logoIcon from "/assets/logo-contapro--regular.png?url";
import { ReactNode, useState } from 'react';
import { BusinessCreationStatus, useBusinessStore } from '../store/BusinessStore.tsx';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import Loader from '../Loader.tsx';
import ErrorAlert from '../errors/ErrorAlert.tsx';
import CredicorpFooter from "@/shared/CredicorpFooter.tsx";
import { eventGa4Service } from "@/ga4.service.tsx";
import { CONTAPRO_ANALYTICS } from "@/shared/analytics.tsx";
import PlansPricesContapro from "@/dashboard/PlansPricesContapro.tsx";
import FormComponent from "./FormComponent.tsx";

interface RegisterBusinessForm {
	name: string;
	ruc: string;
	username: string;
	passwordSOL: string;
	terms?: boolean;
	privacy?: boolean;
}

const validationSchema = Yup.object().shape({
	name: Yup.string().required('Ingrese el nombre de la empresa a registrar'),
	ruc: Yup.string().min(11, 'El RUC debe de tener 11 dígitos.').max(11, 'El RUC debe de tener 11 dígitos.').required('Ingrese el RUC'),
	username: Yup.string().max(8, 'El usuario de SOL solo puede tener hasta 8 caracteres').required('Ingrese nombre de usuario'),
	passwordSOL: Yup.string().required('Ingrese su clave SOL'),
	terms: Yup.boolean().oneOf([true], ''),
	privacy: Yup.boolean().oneOf([true], ''),
});

const ProgressStepsCircleSvg = ({ currentStep, index }: { currentStep: number; index: number }) => {
	return (
		<span
			className={`relative top-[12px] hidden md:flex items-center justify-center w-8 h-8 rounded-full shrink-0 ${
				index < currentStep
					? 'bg-black text-white'
					: index === currentStep
						? 'bg-white border-2 border-black text-black'
						: 'bg-white border-2 border-gray-500 text-gray-500'
			}`}
		>
            {index < currentStep ? (
	            <svg
		            className="w-3.5 h-3.5 text-white lg:w-4 lg:h-4"
		            aria-hidden="true"
		            xmlns="http://www.w3.org/2000/svg"
		            fill="none"
		            viewBox="0 0 16 12"
	            >
		            <path
			            stroke="currentColor"
			            strokeLinecap="round"
			            strokeLinejoin="round"
			            strokeWidth="2"
			            d="M1 5.917L5.724 10.5 15 1.5"
		            />
	            </svg>
            ) : (
	            <span className="f-secondary text-sm font-semibold">
                    { index }
                </span>
            )}
        </span>
	);
};

const ProgressSteps = ({ currentStep, children }: { currentStep: number; children: ReactNode; }) => {
	const steps = [
		{ id: 1, label: 'Registro' },
		{ id: 2, label: 'Registrar empresas' },
		{ id: 3, label: 'Elige tu plan' },
	];

	return (
		<div className="mx-auto">
			<div className="flex items-center gap-2 mb-5 md:px-8">
				<div className="block md:hidden text-center text-gray-500">
					{ currentStep.toString().padStart(2, '0') }/{ steps.length.toString().padStart(2, '0') }
				</div>

				<ol className="flex items-center w-full max-w-[410px] mx-auto">
					{ steps.map((step, index) => (
						<li
							key={ step.id }
							className={ `flex items-center ${index  !== steps.length - 1 ? 'w-full' : 'w-full md:w-auto'} ${ index + 1 < currentStep ? 'text-blue-600' : '' } ${
								index  !== steps.length - 1
									? (index + 1  < currentStep
										? 'after:content-[""] after:w-[80px] after:h-1 md:after:h-0 after:border-b after:border-[#1B68FF] md:after:border-black after:border-4 md:after:border-2 after:inline-block'
										: 'after:content-[""] after:w-[80px] after:h-1 md:after:h-0 after:border-b after:border-[#002a8d40] md:after:border-gray-500 after:border-4 md:after:border-2 after:inline-block')
									: 'after:content-[""] md:after:content-none after:w-[80px] after:h-1 after:border-b after:border-[#002a8d40] after:border-4 after:inline-block'
							}` }
						>
							<div className="flex flex-wrap justify-center w-20">
								<ProgressStepsCircleSvg currentStep={ currentStep } index={ index + 1  }/>
								<span className="font-bold mt-4 text-gray-600 text-center leading-none text-xs whitespace-nowrap">
									{step.label}
								</span>
							</div>
						</li>
					)) }
				</ol>
			</div>
			<div className="mt-10">{ children }</div>
		</div>
	);
};

function NewBusinessOnboardingForm() {
	const [currentStep, setCurrentStep] = useState(2);

	const business = useBusinessStore((state) => state.business);
	const setBusiness = useBusinessStore((state) => state.setBusiness);
	const setProfileSOL = useBusinessStore((state) => state.setProfileSOL);
	const createBusiness = useBusinessStore((state) => state.createBusiness);

	const accountantCreatedSuccessFully = useBusinessStore((state) => state.businessCreatedSuccessFully);
	const errorMessages: ErrorMessage[] = useBusinessStore((state) => state.errorMessages);
	// const paragraphFirstForm = 'Ingresa los datos de tu cliente y visualiza todo el detalle contable.';
	const paragraphSecondForm = 'Tus datos están 100% seguros con nosotros.';

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		clearErrors,
		reset,
	} = useForm<RegisterBusinessForm>({
		resolver: yupResolver(validationSchema),
		mode: 'onBlur',
	});

	const onClickRegisterButton = ({ name, ruc, username, passwordSOL }: RegisterBusinessForm) => {
		setBusiness({
			...business,
			name,
			ruc,
		});

		setProfileSOL({
			username,
			passwordSOL,
		});

		createBusiness()
			.then(() => {				
				if (currentStep === 4) {
					eventGa4Service({
						action: CONTAPRO_ANALYTICS.AUTH.REGISTER_USER_PAGE.BUTTON_REGISTER_FINISHED.ACTION
					})
					
				}
				
				eventGa4Service({
					action: CONTAPRO_ANALYTICS.AUTH.REGISTER_USER_PAGE.BUTTON_REGISTER_COMPANY.ACTION
				})

				setCurrentStep((prevStep) => prevStep + 1);
			})
			.catch((_) => {
				console.error('Error creating business...');
				clearErrors();
			})
			.finally(() => {
				reset();
			});
	};

	return (
		<div className="bg-white min-h-screen w-full">
			<Loader />
			<div className="container mx-auto">
				<div className="p-4">
					<Link to="/inicio"><img src={ logoIcon } alt="Somos contapro"/></Link>
				</div>

				<div className="px-4">
				<ProgressSteps currentStep={ currentStep }>
						<div className={ currentStep === 4 ? "w-full max-w-4xl mx-auto" : "max-w-lg mx-auto" }>
							{accountantCreatedSuccessFully === BusinessCreationStatus.ERROR && (
								<ErrorAlert errorMessages={errorMessages} />
							)}
							{ (currentStep === 2 || currentStep === 3) && (
								<form
									onSubmit={ handleSubmit(onClickRegisterButton) }
									method="POST"
									className="block"
								>
									<div className="text-center">
										<h1 className="text-2xl font-bold mb-2">
											{ currentStep === 2 ? '¡Listo! Ahora registra tu primera empresa' : 'Registra tu segunda empresa' }
										</h1>
										<p className="text-base">
											{ paragraphSecondForm }
										</p>
									</div>
										<FormComponent register={ register } errors={ errors } />
										<div className="mt-4 text-center">
											<button
												type="submit"
												className={ `primary-button-2 disabled:bg-gray-400 py-2 px-8 w-full` }
												disabled={ !isValid }
											>
												Siguiente
											</button>
											{[2, 3].includes(currentStep) && <CredicorpFooter />}
											{
												currentStep === 3 && (
													<button
														className="font-semibold inline-block mt-5 text-[#1B68FF] text-center"
														onClick={() => {
															setCurrentStep(4);
															eventGa4Service({
																action: CONTAPRO_ANALYTICS.AUTH.REGISTER_USER_PAGE.BUTTON_REGISTER_LATER.ACTION
															});
														}}
													>
														Lo haré después
													</button>
												)
											}
										</div>
								</form>
							) }
							{
							  (currentStep === 4) && (
								<PlansPricesContapro />
							  )	
							}
						</div>
					</ProgressSteps>
				</div>
			</div>
		</div>
	);
}

export default NewBusinessOnboardingForm;