import { useNavigate } from "react-router-dom";
import ic_clock_red from "/assets/ic_clock_red.svg?url";
import ic_clock from "/assets/ic_clock.svg?url";
import { eventGa4Service } from '../ga4.service.tsx';
import { CONTAPRO_ANALYTICS } from '../shared/analytics.tsx';
import { getDaysUntilEndDate } from "./helpers.ts";
import { useMemo } from "react";

type PlanType = 'free' | 'expired_plan' | 'active_trial';

interface PlanTypeAlertProps {
    planType: PlanType;
    endDate: string | undefined
}

const setLabelsByTypePlan = (planType: PlanType, endDate?: string) => {
    const remainingDays = getDaysUntilEndDate(endDate)

    if (remainingDays <= 0) {
        planType = "active_trial";
    }
    
    switch (planType) {
        case "free":
            return {
                title: `Tienes ${remainingDays} días de prueba gratis.`,
                subtitle: 'Adquiere un plan para seguir usando Contapro y ahorrar tiempo en la declaración.',
                buttonLabel: "Elegir plan",
                buttonLink: '/planes-y-precios',
                icon: ic_clock,
                bgCard: 'bg-[#EAFEFF]'
            };
        case "expired_plan":
            return {
                title: "Tu plan ha caducado",
                subtitle: "Para registrar más empresas y recibir las propuestas de declaración escoge uno de nuestros planes.",
                buttonLabel: "Renovar plan",
                buttonLink: '/planes-y-precios',
                icon: ic_clock_red,
                bgCard: 'bg-[#FFD5DA]'
            };
            case "active_trial": // Nuevo caso
            return {
                title: "Tu prueba gratis ha terminado",
                subtitle: 'Adquiere un plan para seguir usando Contapro y ahorrar tiempo en la declaración.',
                buttonLabel: "Elegir plan",
                buttonLink: '/planes-y-precios',
                icon: ic_clock_red,
                bgCard: 'bg-[#FFD5DA]'
            };
        default:
            return {
                title: "¡Bienvenido a ContaPro!",
                subtitle: "Tu plan es gratuito, puedes empezar a utilizar todas las funcionalidades de ContaPro.",
                buttonLabel: "Más información",
                buttonLink: ''
            };
    }
}

function PlanTypeAlert({ planType, endDate }: PlanTypeAlertProps) {
    const navigate = useNavigate();
    const labels = useMemo(() => setLabelsByTypePlan(planType, endDate), [planType, endDate]);
    const handleNavigate = (link: string, planType: 'free' | 'expired_plan' | 'active_trial') => {
        if (planType === 'free') {
            eventGa4Service({
                action: CONTAPRO_ANALYTICS.HOME.ALERT_FREE_PLAN.ACTION,
            });
        }
        if (planType === 'active_trial') {
            eventGa4Service({
                action: CONTAPRO_ANALYTICS.HOME.ALERT_FREE_PLAN.ACTION,
            });
        }
        if (planType === 'expired_plan') {
            eventGa4Service({
                action: CONTAPRO_ANALYTICS.HOME.ALERT_EXPIRED_PLAN.ACTION,
            });
        }

        navigate(link, {
            state: {
                planType
            }
        });
    };

    return (
        <div className={`rounded-lg px-6 py-5 flex gap-4 ${labels.bgCard}`}>
            <div className="flex-none w-10 h-auto">
                <img src={labels.icon} alt="ContaPro" className="w-16 h-auto"/>
            </div>
            <div>
                <p className="text-lg font-bold mb-2">{labels.title}</p>
                <p className="text-sm mb-3 f-secondary font-normal">{labels.subtitle}</p>
                
                <button
                    onClick={() => handleNavigate(labels.buttonLink, planType)}
                    className="rounded-[20px] f-secondary font-medium text-base mb-3 sm:mb-0 px-5 py-2 bg-white border border-transparent hover:border-stone-950"
                    type="button"
                >
                    {labels.buttonLabel}
                </button>
            </div>
        </div>
    );
}

export default PlanTypeAlert;
