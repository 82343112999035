import NavBar from "@/shared/NavBar";
import "./AllInProgressBusiness.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Key, useEffect, useState } from "react";
import { useAuthStore } from "@/store/AuthStore";
import icProgressCircular from "/assets/ic_progress_circular.svg?url";

const AllInProgressBusiness = () => {
  const location = useLocation();
  const [filters, setFilters] = useState(location.state?.filters || null);

  useEffect(() => {
    if (!filters) {
      const filters = localStorage.getItem("filters");
      if (filters) {
        setFilters(JSON.parse(filters));
      }
    }
  }, [filters]);

  const { user } = useAuthStore();
  const { subscription } = user;
  const endDate = subscription?.end_date;
  const [daysLeft, setDaysLeft] = useState<number | null>(null);
  const navigate = useNavigate();
  const userHasFreeSubscription = user.subscription?.plan_type === 1;

  useEffect(() => {
    let end_date: Date | undefined;

    if (endDate) {
      end_date = new Date(endDate);
    }
    console.log('end_date', end_date);
    
    if (end_date) {
      const endDate  = new Date(end_date);
      const today = new Date();
      const diffInMs = endDate.getTime() - today.getTime();
      let calculatedDaysLeft = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
      if (calculatedDaysLeft > 7) {
        calculatedDaysLeft = 7;
      }
      if (calculatedDaysLeft <= 0) {
        navigate("/inicio", { state: { daysLeft: calculatedDaysLeft } });
      }
      setDaysLeft(calculatedDaysLeft);
      console.log('calculatedDaysLeft', calculatedDaysLeft);
    }
  }, [endDate, navigate]);

  return (
    <main className="min-h-screen bg-white w-full">
      {userHasFreeSubscription && (
        <>
          <div className="flex items-center justify-center space-x-2 top-banner py-3">
            <p className="text-xs">
              Te quedan {daysLeft} días de prueba gratis 🎁
              <a href="/planes-y-precios" className="text-blue-500 font-semibold underline">
                Adquiere tu plan aquí
              </a>
            </p>
          </div>
        </>
      )}

      <NavBar customCssStyle="border-b" />

      <div className="flex justify-center mt-12">
        <div className="w-2/5 text-center">
          <div className="flex justify-center">
            <img src={icProgressCircular} className="w-20" alt="Icono de progreso" />
          </div>
          <h2 className="text-base font-semibold mt-6">
            Estamos procesando los datos de{" "}
            {filters?.businesses?.length === 1 ? "tu empresa." : "tus empresas."}
          </h2>
          <p className="text-sm mt-4">
            En un máximo de <b>24 horas</b> podrás ver el detalle de los comprobantes de{" "}
            {filters?.businesses?.length === 1 ? "la siguiente empresa." : "las siguientes empresas."}
          </p>
          {filters && filters.businesses && (
            <ul className="list-disc list-inside mt-4">
              {filters.businesses.map(
                (business: { name: string | null | undefined }, index: Key | null | undefined) => (
                  <li key={index}>{business.name}</li>
                ),
              )}
            </ul>
          )}

          <div className="rounded-lg px-6 py-5 mt-8 mx-auto banner bg-gray-100">
            <p className="text-sm mb-3 f-secondary font-normal">
              Mientras esperas, explora todas las funciones de Contapro 🚀
            </p>
            <button
              className="rounded-[20px] f-secondary font-medium text-base mb-3 sm:mb-0 px-5 py-2 text-white bg-gray-800 border border-stone-950"
              type="button"
              onClick={() => window.open('https://youtube.com/@somoscontapro?si=pL7y-STIlTKL7n1D', '_blank')}
            >
              Explora tutoriales
            </button>
            <div className="mt-2">
              <a href="/registrar-empresa" className="text-sm text-blue-500 font-semibold">
                O registra más empresas
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AllInProgressBusiness;